<script lang="ts">
  import FieldShow from "@components/boosting/fields/field-show.svelte";
  import LoginDialog from "@components/login/login-dialog.svelte";
  import Badge from "@components/ux/badge.svelte";
  import { Button } from "@components/ux/button";
  import { Image } from "@components/ux/image";
  import { Input } from "@components/ux/input";
  import QrCode from "@components/ux/qrcode.svelte";
  import Typography from "@components/ux/typography/typography.svelte";
  import {
    gameDetails,
    games,
    getBoostingTextDescription
  } from "@frontend/games/all";
  import { getProductDetails } from "@frontend/games/lol/account_info";
  import { hasOwnProperty } from "@frontend/helpers/has-own-property";
  import { typedEntries } from "@frontend/helpers/typed-entries";
  import { client, getTRPCErrors } from "@helpers/trpc/trpc-client";
  import { portal } from "@helpers/usePortal";
  import Bitcoin from "@images/bitcoin.svg";
  import CloseIcon from "@images/Close.svg";
  import RemoveIcon from "@images/delete-outlined.svg";
  import Played10 from "@images/honor-level.svg";
  import CartIcon from "@images/local_mall.svg";
  import EmailIcon from "@images/mail.svg";
  import RadioChecked from "@images/radio-checked.svg";
  import RadioUnchecked from "@images/radio-unchecked.svg";
  import Rocket from "@images/rocket_launch.svg";
  import Handmade from "@images/sign_language.svg";
  import SkinIncluded from "@images/skin.svg";
  import Stripe from "@images/stripe.svg";
  import type {
    CreateOrderRequest,
    OrderLineItemsType
  } from "@server/types/order-details";
  import {
    cartItems,
    isCartClosed,
    removeCartItem,
    type BoostingCartItem
  } from "@stores/cart";
  import { discountCode, discountPercentage } from "@stores/discounts";
  import { user } from "@stores/user";
  import { loadStripe } from "@stripe/stripe-js";
  import Alert from "flowbite-svelte/Alert.svelte";
  import Drawer from "flowbite-svelte/Drawer.svelte";
  import Modal from "flowbite-svelte/Modal.svelte";
  import { sineIn } from "svelte/easing";
  import { fly } from "svelte/transition";
  import LoadingOutlined from "~icons/ant-design/loading-outlined";
  import Repeat from "~icons/tabler/repeat";
  import StripePaymentMethods from "./stripe-payment-methods.svelte";

  export let stripe_public_key: string;

  let transitionParams = {
    x: 320,
    duration: 200,
    easing: sineIn
  };
  $: total = Object.values($cartItems).reduce(
    (acc, item) => acc + item.price * item.quantity,
    0
  );

  let email = "";
  let isLoadingCheckout = false;
  let checkoutErrors: string[] = [];

  let btcDialogOpen = false;
  let btcAmount = 0;
  let btcAddress = "";
  let btc_timeinterval: NodeJS.Timer | null = null;
  let btcQrcode: string = "";

  let selectedPaymentMethod: "stripe" | "bitcoin" = "stripe";

  let showLoginDialog: boolean = false;

  const startCheckout = async () => {
    const line_items: OrderLineItemsType = Object.values($cartItems);

    // check for auth for buying boosting or any subscription item
    if (
      line_items.some(
        (item) => item.type === "boosting" || item.subscription
      ) &&
      !$user
    ) {
      showLoginDialog = true;
      return;
    }

    isLoadingCheckout = true;

    try {
      if ($user) email = $user.email;

      const orderRequest: CreateOrderRequest = {
        email,
        payment_method: selectedPaymentMethod,
        discount: $discountCode,
        line_items
      };

      const checkoutSession =
        await client.payment.createOrder.mutate(orderRequest);

      if (checkoutSession.payment_method == "stripe") {
        const stripe = await loadStripe(stripe_public_key);

        if (!stripe) {
          isLoadingCheckout = false;
          checkoutErrors = [
            "Failed to initialize Stripe. Please try again later."
          ];
          return;
        }

        if (!checkoutSession.id) {
          checkoutErrors = ["Failed to create an order, please try again."];
          return;
        }

        //remove items from cart
        Object.entries($cartItems).forEach(([id, item]) => removeCartItem(id));

        const result = await stripe.redirectToCheckout({
          sessionId: checkoutSession.id
        });

        if (result.error) {
          checkoutErrors = ["Error redirecting to stripe."];
        }
      } else if (checkoutSession.payment_method == "bitcoin") {
        //close cart
        $isCartClosed = true;

        if (!checkoutSession.total_in_btc || !checkoutSession.address) {
          checkoutErrors = ["Failed to create an order, please try again."];
          return;
        }

        btcAmount = checkoutSession.total_in_btc;
        btcAddress = checkoutSession.address;
        const order_id = checkoutSession.order_id;

        btcQrcode = `bitcoin:${btcAddress}?amount=${btcAmount}`;

        //open dialog
        btcDialogOpen = true;

        if (btc_timeinterval) clearTimeout(btc_timeinterval);

        btc_timeinterval = setInterval(async () => {
          const intent_status =
            await client.payment.orderPaymentStatus.query(order_id);

          if (intent_status.payment_status === "paid") {
            window.location.href = `/payment/success/${order_id}`;
          }
        }, 5000);
      }
    } catch (error) {
      checkoutErrors = getTRPCErrors(error);
    }

    isLoadingCheckout = false;
  };

  const getAddonValue = (
    cartItem: BoostingCartItem,
    addonName: string
  ): boolean | Record<string, any> | undefined => {
    if (hasOwnProperty(cartItem.data.addons, addonName))
      //@ts-ignore
      return cartItem.data.addons[addonName];

    return false;
  };
</script>

<span use:portal={"cart-button"} class="hidden">
  <Button
    variant="secondary"
    class="relative p-[10px]"
    on:click={() => ($isCartClosed = !$isCartClosed)}>
    {#if Object.values($cartItems).length > 0}
      <span
        class="absolute -right-2 -top-2 flex h-5 w-5 items-center justify-center rounded-full bg-destructive">
        <Typography variant="small">
          {Object.values($cartItems).length}</Typography>
      </span>
    {/if}

    <Image src={CartIcon} alt="cart" class="h-5 w-5" />
  </Button>
</span>

<Drawer
  placement="right"
  transitionType="fly"
  {transitionParams}
  bind:hidden={$isCartClosed}
  id="sidebar1"
  class="min-w-[336px] overflow-x-hidden bg-light-black !p-6  !pb-0"
  width="max-w-sm flex flex-col">
  <div class="mb-6 flex items-center justify-between">
    <Typography variant="lead">
      Cart <span class="text-medium-gray"
        >({Object.keys($cartItems).length})</span>
    </Typography>
    <Button
      on:click={() => ($isCartClosed = true)}
      variant="secondary"
      class="p-[10px]"><Image src={CloseIcon} /></Button>
  </div>
  <div class="-ml-6 mb-4 min-h-[1px] w-[calc(100%+48px)] bg-dark-gray" />

  <div class="flex flex-1 flex-col gap-2">
    {#if Object.values($cartItems).length}
      <aside class="flex flex-1 flex-col gap-2">
        {#each Object.entries($cartItems) as [id, cartItem] (id)}
          <div
            transition:fly={transitionParams}
            class="grid gap-4 rounded-xl border border-dark-gray bg-theme-black p-4">
            <div class="flex flex-row items-center justify-between">
              <div class="flex items-center gap-2">
                <div
                  class="flex h-12 w-12 items-center justify-center rounded-[8px] border border-dark-gray bg-blue-gray">
                  {#if cartItem.type === "account"}
                    <img
                      src={gameDetails["League of Legends"].icon}
                      alt="Product"
                      class="h-6" />
                  {:else}
                    <img
                      src={gameDetails[cartItem.data.game].icon}
                      alt="Product"
                      class="h-6" />
                  {/if}
                </div>
                <div class="flex flex-col">
                  <div class="flex gap-2">
                    {#if cartItem.type === "account"}
                      {@const details = getProductDetails(
                        cartItem.data.productId
                      )}
                      {details?.region.toUpperCase()} Level {details?.level}
                    {:else}
                      {getBoostingTextDescription(cartItem.data)}
                    {/if}
                  </div>
                  <div class="text-muted">
                    {#if cartItem.type === "account"}
                      Smurf Account
                    {:else}
                      {games?.[cartItem.data.game]?.[cartItem.data.type]?.title}
                    {/if}
                  </div>
                </div>
              </div>

              <div class="flex items-center gap-2">
                <div class="flex items-center p-2 text-base font-semibold">
                  {cartItem.currency}{cartItem.price.toFixed(2)}
                  {#if cartItem.subscription}<span
                      class="text-xs text-neutral-500">/Month</span>
                  {/if}
                </div>
                <button
                  disabled={isLoadingCheckout}
                  class=" inline-flex max-w-fit items-center"
                  on:click={() => removeCartItem(id)}
                  ><Image class="h-6  " src={RemoveIcon} /></button>
              </div>
            </div>

            <div class="flex w-full flex-row flex-wrap justify-start gap-2">
              {#if cartItem.type === "account"}
                {@const details = getProductDetails(cartItem.data.productId)}
                <Badge image={Rocket} imageAlt={"Instant Delivery"}>
                  Instant Delivery
                </Badge>
                {#if details?.accountType == "hand"}
                  <Badge image={Handmade} imageAlt={"Handmade"}>Handmade</Badge>
                {/if}
                {#if details?.mmrType === "played"}
                  <Badge image={Played10} imageAlt={"Handmade"}
                    >10 Normal Games Played</Badge>
                {/if}
                {#if cartItem.subscription}
                  <Badge>
                    <Repeat class="mr-2 h-6 w-6" /> Subscription Discount: 60%
                  </Badge>
                {/if}
              {/if}
              {#if $discountPercentage}
                <Badge>
                  Discount: {$discountPercentage * 100}%
                </Badge>
              {/if}
              {#if cartItem.price == 0}
                <Badge
                  class=" flex h-12 w-fit items-center justify-center rounded-[8px] border border-dark-gray bg-blue-gray px-3 py-2"
                  >FREE</Badge>
              {/if}
              {#if cartItem.type === "account" && cartItem.data.selected_skin}
                <Badge image={SkinIncluded} imageAlt={"Skin Included"}>
                  Skin Included: {cartItem.data.selected_skin}
                </Badge>
              {/if}

              {#if cartItem.type === "boosting"}
                {@const selectedTypeDetails =
                  games?.[cartItem.data.game]?.[cartItem.data.type]}
                {#each Object.entries(selectedTypeDetails?.fields || {}) as [fieldName, field]}
                  {#if hasOwnProperty(cartItem.data, fieldName)}
                    <div class="flex w-full flex-wrap items-center gap-4">
                      <Typography color="muted" variant="small"
                        >{field.subtitle}:</Typography>
                      <Typography
                        class="flex items-center gap-2"
                        variant="small"
                        color="light_gray">
                        <FieldShow {field} value={cartItem.data[fieldName]} />
                      </Typography>
                    </div>
                  {/if}
                {/each}

                {#each typedEntries(selectedTypeDetails?.addons || {}) as [addonName, addon]}
                  {#if getAddonValue(cartItem, addonName)}
                    <Badge>{addon.title}</Badge>
                  {/if}
                {/each}
              {/if}
            </div>
          </div>
        {/each}
      </aside>

      <div
        class="-ml-6 mt-4 w-[calc(100%+48px)] border-t border-dark-gray bg-theme-black p-4">
        <div class="flex items-center justify-between pb-4">
          <Typography color="muted" class="font-normal"
            >Order Total:</Typography>
          <Typography variant="h4" as="p">
            {Object.values($cartItems)[0]?.currency}{total.toFixed(
              2
            )}</Typography>
        </div>

        <div class="-ml-6 mb-4 min-h-[1px] w-[calc(100%+48px)] bg-dark-gray" />

        <div class="relative pb-4 text-white">
          <form on:submit|preventDefault={() => startCheckout()} class="w-full">
            {#if $user}
              <div class="mt-2 rounded-xl border border-dark-gray">
                <Image
                  src={EmailIcon}
                  class="ml-[16px]  inline-block h-6 w-6" />
                <Input
                  id="email_input"
                  disabled={true}
                  value={$user.email}
                  class="-ml-1 inline-block w-[80%] border-none px-[12px] py-6 font-poppins text-[16px] font-[500] leading-[150%] placeholder:!font-normal placeholder:text-medium-gray focus-visible:ring-0 focus-visible:ring-offset-0"
                  placeholder="Enter your email">
                </Input>
              </div>
            {:else}
              <div class="mt-2 rounded-xl border border-dark-gray">
                <Image
                  src={EmailIcon}
                  class="ml-[16px]  inline-block h-6 w-6" />
                <Input
                  id="email_input"
                  disabled={isLoadingCheckout}
                  bind:value={email}
                  class="-ml-1 inline-block w-[80%] border-none px-[12px] py-6 font-poppins text-[16px] font-[500] leading-[150%] placeholder:!font-normal placeholder:text-medium-gray focus-visible:ring-0 focus-visible:ring-offset-0"
                  placeholder="Enter your email">
                </Input>
              </div>
            {/if}
          </form>
        </div>

        <div class="-ml-6 mb-4 min-h-[1px] w-[calc(100%+48px)] bg-dark-gray" />

        <Typography class="pb-2">Payment Method</Typography>
        {#if checkoutErrors.length > 0}
          <Alert color="red">
            <svelte:fragment slot="icon">
              <svg
                aria-hidden="true"
                class="mr-2 h-5 w-5 text-red-700"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
                ><path
                  fill-rule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                  clip-rule="evenodd" /></svg>
            </svelte:fragment>
            <span class="sr-only">Info</span>
            <span class="font-medium"
              >Errors encountered while checking out:</span>
            <ul class="ml-8 mt-0 list-inside list-disc">
              {#each checkoutErrors as error}
                <li>{error}</li>
              {/each}
            </ul>
          </Alert>
        {/if}
        <div class="grid w-full gap-2">
          {#if !isLoadingCheckout}
            <div class="mb-2 flex flex-col gap-2">
              <div>
                <input
                  name="paymentMethod"
                  type="radio"
                  id="stripe"
                  value="stripe"
                  class="peer hidden"
                  bind:group={selectedPaymentMethod} />
                <label
                  for="stripe"
                  class="flex cursor-pointer gap-2 rounded-2xl border-2 border-dark-gray p-3 peer-checked:border-accent peer-checked:bg-blue-gray"
                  ><Image src={Stripe} alt="Stripe" class="h-6 w-6" /> Stripe
                  <StripePaymentMethods />
                  <Image
                    src={selectedPaymentMethod === "stripe"
                      ? RadioChecked
                      : RadioUnchecked}
                    alt=""
                    class="ml-auto h-6 w-6" />
                </label>
              </div>
              <div>
                <input
                  name="paymentMethod"
                  type="radio"
                  id="bitcoin"
                  value="bitcoin"
                  class="peer hidden"
                  bind:group={selectedPaymentMethod} />
                <label
                  for="bitcoin"
                  class="flex cursor-pointer gap-2 rounded-2xl border-2 border-dark-gray p-3 peer-checked:border-accent peer-checked:bg-blue-gray">
                  <Image src={Bitcoin} alt="Bitcoin" class="h-6 w-6" /> Bitcoin
                  <Image
                    src={selectedPaymentMethod === "bitcoin"
                      ? RadioChecked
                      : RadioUnchecked}
                    alt=""
                    class="ml-auto h-6 w-6" />
                </label>
              </div>
            </div>
            <div
              class="-ml-6 mb-4 min-h-[1px] w-[calc(100%+48px)] bg-dark-gray" />
            <Button
              variant="accent"
              on:click={() => startCheckout()}
              class="w-full rounded-xl">
              Buy Now
            </Button>
          {:else}
            <LoadingOutlined class="m-auto inline h-8 w-8 animate-spin" />
          {/if}
        </div>
      </div>
    {:else}
      <p>Your cart is empty!</p>
    {/if}
  </div>
</Drawer>

<Modal title="Awaiting Payment!" bind:open={btcDialogOpen}>
  <div class="break-words">
    Send {btcAmount} to address {btcAddress} <br />
  </div>
  <div class="flex justify-center py-4">
    <QrCode value={btcQrcode} />
  </div>
  Or Click here{" "}
  <a class="font-bold" href={`bitcoin:${btcAddress}?amount=${btcAmount}`}>
    to use your offline wallet{" "}
  </a>
  <br />
  <b class="text-red-500">
    Note: This address is only available for 30 minutes.
  </b>
  <br />
  <div class="flex w-full items-center">
    <LoadingOutlined class="m-auto inline h-8 w-8 animate-spin text-center" />
  </div>
</Modal>
<LoginDialog
  bind:open={showLoginDialog}
  bind:email
  onOpenChange={() => (showLoginDialog = !showLoginDialog)} />
